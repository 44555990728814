<template>
  <b-card>
    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility7"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Transfer Student</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility7 = false"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="">
          <b-col md="12" class="mb-1">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  size="44"
                  :src="chObj.picture"
                  variant="light-primary"
                />
              </template>

              <b-link
                class="font-weight-bold d-block text-nowrap"
                style="margin-bottom: 4px"
              >
                <b-badge variant="light-primary">
                  {{ chObj.name }}
                </b-badge>
              </b-link>
              <small class="text-muted">
                <b-badge variant="light-primary" class="mr-50">
                  GR -
                  {{
                    reportDomain === "myskoolhpgs" ||
                    reportDomain === "myskoolprofectus"
                      ? chObj.grNoLocal
                      : chObj.grNo
                  }}
                </b-badge>
                <b-badge variant="light-primary" class="mr-50">
                  F# - {{ chObj.family_code }}
                </b-badge>
                <b-badge variant="light-primary">
                  {{
                    reportDomain === "myskoolhpgs" ||
                    reportDomain === "myskoolprofectus"
                      ? "CID: "
                      : "ID: "
                  }}

                  {{
                    reportDomain === "myskoolhpgs" ||
                    reportDomain === "myskoolprofectus"
                      ? chObj.grNo
                      : chObj.id
                  }}
                </b-badge>
              </small>
            </b-media>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Current Campus">
              <b-badge variant="primary">
                {{ getCampus(chObj.campusID) }}
              </b-badge>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Transfer to Campus"
              invalid-feedback="Campus is required."
              ref="ch_camp"
            >
              <v-select
                v-model="chObj.newCampus"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="campuses"
                :clearable="false"
                label="branch"
                :reduce="(val) => val.id"
                :disabled="clsLoading"
                placeholder="Select campus"
                @input="LoadClass()"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Class"
              invalid-feedback="class is required."
              ref="ch_cls"
            >
              <v-select
                v-model="chObj.clsID"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="chOptions"
                :clearable="false"
                label="name"
                :reduce="(val) => val.id"
                :disabled="clsLoading"
                placeholder="Select class"
                @input="FillChSec()"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Section"
              invalid-feedback="Section is required."
              ref="ch_sec"
            >
              <v-select
                v-model="chObj.secID"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="chSections"
                :reduce="(opt) => opt.id"
                label="section"
                :clearable="false"
                :disabled="clsLoading"
                placeholder="Select section"
                @input="checkChSec()"
              />
            </b-form-group>
          </b-col>

          <b-col class="mt-1" md="12">
            <b-button
              type="submit"
              variant="success"
              block
              :disabled="request"
              @click="saveCh()"
            >
              <b-spinner v-if="request" small type="grow" />
              <span v-else>Transfer</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-row align-h="center">
      <!-- User Info: Left col -->
      <b-col class="d-flex justify-content-between flex-column">
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-center pt-3">
          <b-avatar
            :src="userData.picture"
            :text="avatarText(userData.fullName)"
            :variant="`light-primary`"
            size="120px"
            rounded
          />
        </div>

        <div class="d-flex flex-column mt-1 text-center">
          <div class="mb-1">
            <h4 class="mb-0">
              {{ userData.name }}
            </h4>
            <span class="card-text">{{ item.meta_class }}</span>
            <template v-if="reportDomain === 'myskoolhpgs'">
              <div class="mb-50 mt-50">
                <b-badge variant="primary" class="font-small-3">
                  Current Campus: {{ getCampus(userData.campusID) }}
                </b-badge>
              </div>
              <div class="">
                <b-badge variant="primary" class="font-small-3">
                  Admitted Campus: {{ getCampus(userData.campus_Admitted) }}
                </b-badge>
              </div>
            </template>
          </div>
        </div>
      </b-col>
    </b-row>

    <b-row align-h="center" class="pt-1 pb-1" v-if="false">
      <!-- User Info: Left col -->
      <b-col cols="21">
        <!-- User Stats -->
        <div class="d-flex align-items-center mt-1">
          <div class="d-flex align-items-center mr-2">
            <b-avatar variant="light-primary" rounded size="38">
              <feather-icon icon="CheckCircleIcon" size="22" />
            </b-avatar>
            <div class="ml-1">
              <h4 class="mb-0">82%</h4>
              <small class="font-weight-bold">Attendace</small>
            </div>
          </div>

          <div class="d-flex align-items-center">
            <b-avatar variant="light-success" rounded size="38">
              <feather-icon icon="BriefcaseIcon" size="22" />
            </b-avatar>
            <div class="ml-1">
              <h4 class="mb-0">99.87k</h4>
              <small class="font-weight-bold">Project Done</small>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <hr class="border-top-secondary" />

    <b-row class="mt-2">
      <!-- User Info: Left col -->

      <b-col cols="12" class="d-flex justify-content-between flex-column">
        <h5 class="font-weight-light text-uppercase mb-2">Details</h5>
        <div class="d-flex align-items-center mb-50">
          <h5 class="font-weight-bolder mb-0 mr-50">Username:</h5>
          <span>{{ userData.name }}</span>
        </div>
        <div class="d-flex align-items-center mb-50">
          <h5 class="font-weight-bolder mb-0 mr-50">Status:</h5>
          <b-badge :variant="getVariant(userData.status.toLowerCase())">
            <span class="text-capitalize">{{ userData.status }}</span>
          </b-badge>
        </div>
        <div class="d-flex align-items-center mb-50">
          <h5 class="font-weight-bolder mb-0 mr-50">Admission Date:</h5>
          <b-badge variant="light-primary" v-if="userData.date_of_admission">
            {{
              new Date(userData.date_of_admission).toLocaleDateString("en-UK", {
                year: "numeric",
                day: "numeric",
                month: "short",
              })
            }}
          </b-badge>
        </div>
        <div
          class="d-flex align-items-center mb-50"
          v-if="['left', 'alumni'].includes(userData.status.toLowerCase())"
        >
          <h5 class="font-weight-bolder mb-0 mr-50">Leaving Date:</h5>
          <b-badge variant="light-primary" v-if="userData.date_of_leaving">
            {{
              new Date(userData.date_of_leaving).toLocaleDateString("en-UK", {
                year: "numeric",
                day: "numeric",
                month: "short",
              })
            }}
          </b-badge>
        </div>
        <div class="d-flex align-items-center mb-50">
          <h5 class="font-weight-bolder mb-0 mr-50">Role:</h5>
          <span class="text-capitalize">Student</span>
        </div>
        <div class="d-flex align-items-center mb-50">
          <h5 class="font-weight-bolder mb-0 mr-50">Country:</h5>
          <span class="text-capitalize">Pakistan</span>
        </div>
        <div class="d-flex align-items-center">
          <h5 class="font-weight-bolder mb-0 mr-50">Contact:</h5>
          <span class="text-capitalize">{{
            userData.contact_student || userData.contact_father
          }}</span>
        </div>
      </b-col>
      <!-- <b-col cols="21" class="d-flex justify-content-between flex-column">
        
        <b-col cols="12">
          <table class="mt-2 mt-xl-0 w-100">
            <tr>
              <th class="pb-50">
                <feather-icon icon="CheckIcon" class="mr-75" />
                <span class="font-weight-bold">Status</span>
              </th>
              <td class="pb-50 pl-50 text-capitalize">
                {{ userData.status }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="StarIcon" class="mr-75" />
                <span class="font-weight-bold">Role</span>
              </th>
              <td class="pb-50 pl-50 text-capitalize">
                {{ userData.role }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="FlagIcon" class="mr-75" />
                <span class="font-weight-bold">Country</span>
              </th>
              <td class="pb-50 pl-50">
                {{ userData.country }}
              </td>
            </tr>
            <tr>
              <th>
                <feather-icon icon="PhoneIcon" class="mr-75" />
                <span class="font-weight-bold">Contact</span>
              </th>
              <td class="pl-50">
                {{ userData.contact }}
              </td>
            </tr>
          </table>
        </b-col> 
      </b-col> -->
    </b-row>

    <b-row align-h="center" class="mt-2">
      <b-col cols="21">
        <b-button
          :to="{ name: 'manage-student', params: { id: userData.id } }"
          variant="primary"
        >
          Edit
        </b-button>
        <b-button
          v-if="
            reportDomain === 'myskoolhpgs' &&
            ['registered', 'present'].includes(userData.status.toLowerCase())
          "
          variant="outline-primary"
          class="ml-1"
          @click="openCh()"
          >Transfer Campus
        </b-button>
        <!-- <b-button variant="outline-danger" class="ml-1"> Suspend </b-button> -->
        <!-- <div class="d-flex"></div> -->
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BButton,
  BAvatar,
  BRow,
  BCol,
  BBadge,
  BSidebar,
  BMedia,
  BForm,
  BFormGroup,
  BFormInput,
  BSpinner,
  BLink,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import useUsersList from "../users-list/useUsersList";
import vSelect from "vue-select";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    BBadge,
    BSidebar,
    BMedia,
    BForm,
    BFormGroup,
    BFormInput,
    BSpinner,
    BLink,
    vSelect,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    campuses: {
      type: Array,
      required: true,
    },
    getCampus: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      reportDomain: this.$store.state.report.replace(/_/g, ""),
      chObj: {},
      visibility7: false,
      chOptions: [],
      chClasses: [],
      chSections: [],
      clsLoading: false,
      request: false,
    };
  },
  setup() {
    const { resolveUserRoleVariant } = useUsersList();
    return {
      avatarText,
      resolveUserRoleVariant,
    };
  },
  methods: {
    ...mapActions({
      get: "get",
      post: "post",
      put: "put",
      delete: "delete",
    }),

    getVariant(st) {
      const vt =
        st == "present"
          ? "light-success"
          : st == "registered"
          ? "light-warning"
          : "light-danger";
      return vt;
    },

    openCh() {
      this.chObj = {
        ...this.userData,
        newCampus: 0,
        clsID: 0,
        secID: 0,
      };

      this.visibility7 = true;
      this.chSections = [];
      this.chClasses = [];
      this.chOptions = [];

      // console.log(this.chObj);
    },
    async LoadClass() {
      this.checkChCamp();
      this.clsLoading = true;
      this.chObj.clsID = 0;
      this.chObj.secID = 0;
      this.chSections = [];

      var obj = {
        url:
          this.$store.state.domain +
          "myclasses/LoadClasses?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.chObj.newCampus +
          "&dID=0",
        token: this.$store.state.userData.token,
      };
      //  this.items =[];
      let myitem = await this.get(obj);
      this.chClasses = myitem;
      this.chOptions = this.chClasses.map((el) => el.cls);

      this.clsLoading = false;
    },
    FillChSec() {
      this.chObj.secID = 0;
      this.chSections = [];
      let obj = this.chClasses.find((el) => el.cls.id == this.chObj.clsID);
      if (obj && obj.sections.length > 0) {
        this.chSections = obj.sections;
      } else this.chSections = [];

      this.checkChClass();
    },
    checkChCamp() {
      var elem = this.$refs["ch_camp"];
      if (this.chObj.newCampus == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkChClass() {
      var elem = this.$refs["ch_cls"];
      if (this.chObj.clsID == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkChSec() {
      var elem = this.$refs["ch_sec"];
      if (this.chObj.secID == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async saveCh() {
      this.checkChCamp();
      this.checkChClass();
      this.checkChSec();
      if (
        this.checkChCamp() == false ||
        this.checkChClass() == false ||
        this.checkChSec() == false
      ) {
        this.$bvToast.toast("Please select all the required fields", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      }
      // else if (this.chObj.campusID == this.chObj.newCampus) {
      //   this.$bvToast.toast("Current campus and transfer campus are same", {
      //     title: "Error!",
      //     variant: "danger",
      //     toaster: "b-toaster-top-center",
      //   });
      // }
      else {
        let result = await Swal.fire({
          title: "Are you sure?",
          text: "Do you want to transfer the student?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          confirmButtonText: "Yes!",
        });

        if (result.isConfirmed) {
          this.request = true;
          let data = {
            sID: this.chObj.id,
            previousCampus: this.chObj.campusID,
            newCampus: this.chObj.newCampus,
            clsID: this.chObj.clsID,
            secID: this.chObj.secID,
            userID: this.$store.state.userData.userID,
          };
          // console.log(data);

          let status = await this.post({
            url:
              this.$store.state.domain +
              "Students/TransferCampus?db=" +
              this.$store.state.userData.db,
            body: data,
            message: "Student transferred successfully!",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.request = false;
          if (status) {
            this.visibility7 = false;
            this.$router.go();
          }
        }
      }
    },
  },
};
</script>

<style></style>
